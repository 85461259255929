import React from "react";
import PropTypes from "prop-types";

const ListItem = ({ children }) =>
  children.length > 0 ? (
    <li className="offerView__title offerView__title_bg">{children}</li>
  ) : null;

ListItem.prototype = {
  children: PropTypes.string,
};

export default ListItem;
