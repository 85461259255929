import React, { useContext } from "react";
import { PageContext } from "../../../store/context/page-context";
import ListItem from "./ListItem";
import TrackedLink from "../../Analytics/TrackedLink";

const SectionViewBig = () => {
  const { eventCategory, isCn, translate: t } = useContext(PageContext);
  return (
    <>
      {isCn && t("BigOfferView_CnVersion").length > 0 ? (
        <p className="offerView__title offerView__title_bg offerView__title_bg-cn">
          {t("BigOfferView_CnVersion")}
        </p>
      ) : (
        <>
          <p className="offerView__title offerView__title_sm">
            {t("BigOfferView_FirstTitle")}
          </p>
          <p className="offerView__title offerView__title_bg">
            <span>{t("BigOfferView_MainTitle")}</span>
          </p>
          <ul>
            <ListItem>{t("BigOfferView_List_FirstItem")}</ListItem>
            <ListItem>{t("BigOfferView_List_SecondItem")}</ListItem>
            <ListItem>{t("BigOfferView_List_ThirdItem")}</ListItem>
            <ListItem>{t("BigOfferView_List_FourthItem")}</ListItem>
          </ul>
          <p className="offerView__title offerView__title_bg">
            {`${t("BigOfferView_List_LastItem")} `}
            <span>{t("BigOfferView_List_LastItem_Price")}</span>
          </p>
          <p className="offerView__title offerView__title_ds">{`${t(
            "BigOfferView_Bottom_Tittle"
          )}!`}</p>
        </>
      )}
      <TrackedLink
        class="offerView__btn"
        category={eventCategory}
        action="Get it now"
        path={t("BigOfferView_Button_Path")}
        target={!isCn}
        redash={false}
      >
        {t("BigOfferView_Button")}
      </TrackedLink>
    </>
  );
};

export default SectionViewBig;
