import React, { useContext, useState } from "react";
import FeedbackView from "../../components/ext-questionnaire/Feedback";
import { PageContext } from "../../store/context/page-context";
import PropTypes from "prop-types";

const FeedbackViewContainer = ({ software }) => {
  const { email, isCn } = useContext(PageContext);

  const [textField, setTextField] = useState("");

  const onChangeHandler = (e) => setTextField(e.target.value);
  const addParams = {
    email: encodeURIComponent(email),
    software: encodeURIComponent(software),
    ...(isCn ? { locale: "zh" } : {}),
  };
  const params = new URLSearchParams(addParams).toString();
  const redirectPath = `${process.env.MAIN_HOST}${isCn ? "/cn/" : ""}`;

  const sendFeedback = () => {
    if (textField) {
      const headers = {
        Accept: "application/json",
        "Content-type": "text/plain",
      };
      fetch(`${process.env.API_HOST}/review/uninstall?${params}`, {
        headers,
        method: "POST",
        body: textField,
      }).finally(() => window.open(redirectPath, "_self"));
    }
  };

  return (
    <FeedbackView
      textField={textField}
      onChangeHandler={onChangeHandler}
      sendFeedback={sendFeedback}
    />
  );
};

FeedbackViewContainer.propTypes = {
  software: PropTypes.string.isRequired,
};

export default FeedbackViewContainer;
