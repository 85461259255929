import React, { useContext } from "react";
import PropTypes from "prop-types";
import { PageContext } from "../../../store/context/page-context";
import { getTrueClasses } from "../../../helpers";
import SectionViewBig from "./SectionViewBig";
import SectionView from "./SectionView";
import "./index.scss";

const OfferView = ({ isBig, page }) => {
  const { isCn, translate: t } = useContext(PageContext);

  return (
    <section
      className={getTrueClasses(
        "offerView",
        page && `offerView_${page}`,
        isCn && `offerView_cn`
      )}
    >
      <p className="offerView__title offerView__title_md">
        {t("OfferView_TopTitle")}
      </p>
      {isBig ? <SectionViewBig /> : <SectionView page={page} />}
    </section>
  );
};

OfferView.prototype = {
  page: PropTypes.string,
};

export default OfferView;
