import React, { useContext } from "react";
import TrackedLink from "../../Analytics/TrackedLink";
import { PageContext } from "../../../store/context/page-context";
import "./index.scss";

const LinkView = () => {
  const { eventCategory, isCn, translate: t } = useContext(PageContext);

  return (
    <section className="linkView">
      <TrackedLink
        class="linkView__link"
        action={t("LinkView_Title")}
        category={eventCategory}
        path={t("LinkView_Path")}
        target={!isCn}
        redash={false}
      >
        {t("LinkView_Title")}
      </TrackedLink>
    </section>
  );
};

export default LinkView;
