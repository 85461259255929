import React, { useContext } from "react";
import PropTypes from "prop-types";
import { PageContext } from "../../../store/context/page-context";
import { SECTION_VIEW_TWO_TEXT } from "./index.content";
import parse from "html-react-parser";
import TrackedLink from "../../Analytics/TrackedLink";

const SectionView = ({ page }) => {
  const { eventCategory, isCn, translate: t } = useContext(PageContext);

  const keySectionViewTwo =
    page && Object.keys(SECTION_VIEW_TWO_TEXT).includes(page) ? page : "common";

  const { firstTitle, mainTitle, secondTitle, bottomTitle, button } =
    SECTION_VIEW_TWO_TEXT[keySectionViewTwo];
  return (
    <>
      <p className="offerView__title offerView__title_sm">{t(firstTitle)}</p>
      <p className="offerView__title offerView__title_bg">
        {parse(t(mainTitle))}
      </p>
      {secondTitle && (
        <p className="offerView__title offerView__title_bg">
          {parse(t(secondTitle))}
        </p>
      )}
      <p className="offerView__title offerView__title_ds">{t(bottomTitle)}</p>
      <TrackedLink
        class="offerView__btn"
        category={eventCategory}
        action="Get it now"
        path={t(button.path)}
        redash={false}
      >
        {t(button.text)}
      </TrackedLink>
    </>
  );
};

SectionView.prototype = {
  page: PropTypes.string,
};

export default SectionView;
