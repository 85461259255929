import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { trackGa } from "../../tracking/googleAnalytics";
import { PageContext } from "../../store/context/page-context";
import { updateContent } from "../../components/ext-questionnaire/Select/updateContent";
import {
  NUMBERS,
  PATH,
  USER_STATUSES,
} from "../../components/ext-questionnaire/index.content";
import SelectView from "../../components/ext-questionnaire/Select";

const SelectViewContainer = ({ switchCurrentView }) => {
  const {
    eventCategory,
    status,
    page,
    translate: t,
    isCn,
  } = useContext(PageContext);

  const [getSelectState, setSelectState] = useState(null);
  const content = updateContent(t);
  let key = "common";
  if (page === "extpro" && status === USER_STATUSES.p) {
    key = "paid";
  }
  if (page === "extnewpro") {
    key = "newpro";
  }
  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectState(value);

    if (page === "extnewpro" && value === "option3") {
      const path = isCn ? PATH.option3.cn : PATH.option3.en;

      trackGa("PRO Extension can’t do what I need", {
        category: eventCategory,
        label: path,
        transport_type: "beacon",
      });
      setTimeout(() => {
        setSelectState(null);
        window.open((window.location.href = path), "_self");
      }, 800);
    } else {
      trackGa("select", {
        category: eventCategory,
        label: value,
      });

      const switchNumber = NUMBERS[value] || 0;

      setTimeout(() => {
        switchCurrentView(switchNumber);
      }, 800);
    }
  };

  return (
    <SelectView
      getSelectState={getSelectState}
      handleSelectChange={handleSelectChange}
      selectFormContent={content[key]}
    />
  );
};

SelectViewContainer.propTypes = {
  switchCurrentView: PropTypes.func.isRequired,
};

export default SelectViewContainer;
